<template>
    <div>
        <!--<div>{{code}}</div>
        <div>{{query}}</div>
        <div>{{info}}</div>-->
    </div>
</template>

<script>
import { reactive, toRefs ,onMounted } from 'vue';
import Common from "../library/Common";
import { useRouter } from 'vue-router';
import myRequest from '../library/MyRequest';
import { Dialog } from 'vant';
export default {
    name: 'index',
    setup(){
        const router = new useRouter();
        const common = Common;

        //授权回调页面，获取code和state
        const code = router.currentRoute.value.query.code;
        const query = JSON.parse(router.currentRoute.value.query.state);
    
        let state = reactive({
           // img:"https://thirdwx.qlogo.cn/mmopen/vi_32/LW2k6KW4rgiar5x9pyOEIrV1MYV5icnaiavFElFUeibtSOA1oTYHL4lHWuzAtmjZVibntmq73IJX4V1dPC2jtXicTtBQ/132",
           // openid:"",
           code:code,
           query:query,
           info:"info",
        });
        
        const getWxInfo = async()=>{
            state.info = "getWxInfo";
            var wxInfo = null;
            try{
                wxInfo = await myRequest.get(`activity/get-user-info/?code=${state.code}`);
            }catch(e){
                state.info = e.response.status;
            }
            
            state.info = wxInfo.data.avatar_url;
            if(wxInfo){
                //获取userInfo
                var userInfo = common.getUserInfo();
                if(userInfo){
                    common.setWxUserInfo(userInfo,wxInfo);
                    router.push({name: 'ticket'});
                }else{
                    Dialog({
                        message: common.getLanguage('pleaseLogInAgain'),
                    }).then(() => {
                        router.push({name: 'login'});
                    });
                }
            }else{
                Dialog({
                    message:common.getLanguage('Failedtogetuserinfor'),
                }).then(() => {
                    router.push({name: 'login'});
                });
            }
        }
        onMounted(async()=>{
            await getWxInfo();
        })
        
        return {
            ...toRefs(state),
        };
    }
}
</script>

<style scoped>

</style>